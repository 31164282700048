const TOTAL_CAKES=5
const TOTAL_CONES=10
const TOTAL_BOWS=4
const TOTAL_CANDY=2

function getCake(r: number) {
  return 'cake' + Math.ceil(r * TOTAL_CAKES)
}

function getCone(r: number) {
  return 'cone' + Math.ceil(r * TOTAL_CONES)
}

function getIcon(icon: IconOptions, r: number): string {
  return icon + Math.ceil(r)
}

type RotateOptions = 3 | 6 | 12
export type IconOptions = 'cake' | 'cone' | 'bow' | 'candy' | 'cake-logo'
type SizeOptions = 'big' | 'medium' | 'small'

export const DessertIcon = (props: {
  rand: number, // Can't be computed in the component due to hydration, always pass Math.random()
  icon?: IconOptions, 
  rotate?: RotateOptions, 
  size?: SizeOptions, 
  className?: string
}) => {
  let icon = props.icon as string
  let rotate = ''

  const rand = props.rand
  const cn = props.className || ''

  if (props.rotate) {
    if (typeof props.rotate === 'string') {
      props.rotate = parseInt(props.rotate) as RotateOptions
    }

    rotate = props.rotate < 0 ?
      `-rotate-${Math.abs(props.rotate)}` :
      `rotate-${props.rotate}`
  }

  console.log('rand', rand, props)

  switch (props.icon) {
    case 'cake':
      icon = getCake(rand)
      break
    case 'cone':
      icon = getCone(rand)
      break
    case 'bow':
      icon = getIcon(props.icon, rand * TOTAL_BOWS)
      break
    case 'candy':
      icon = getIcon(props.icon, rand * TOTAL_CANDY)
      break
    case 'cake-logo':
      icon = 'cake1'
      break
    default:
      icon = rand > 0.5 ? getCake(rand) : getCone(rand)
  }

  // Set the height (which automatically sets the width)
  let size
  switch(props.size) {
    case 'big':
      size = 'h-20'
      break
    case 'medium':
      size = 'h-14'
      break
    default:
      size = 'h-6'
  }

  return (
    <img src={`${process.env.NEXT_PUBLIC_BASE_URL}/assets/images/dessertdata/${icon}.png`} className={`${size} ${rotate} inline-block ${cn}`} />
  )
}